<template>
  <TransitionGroup name="fade-absolute">
    <ActionForm
      v-if="currentStep === 1 || currentStep === 2"
      key="action-form"
      class="action-form"
      :class="{
        'is-loaded': isReadyForGenerate,
      }"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      :is-bubble-notification-visible="false"
      @validate="onValidate"
    >
      <template #default="{ errors, action }">
        <div class="d-flex flex-column mb-8">
          <AppText size="17px" mb="10px" class="font-medium">
            {{ t('signup.invitation.title') }}
          </AppText>
          <AppText :line-height="1.3" :opacity="0.6">
            {{ t('signup.invitation.description') }}
          </AppText>
        </div>

        <ConnectedAlert>
          {{ companyName }}
        </ConnectedAlert>
        <ConnectedAlert>
          {{ email }}
        </ConnectedAlert>

        <FSelect
          v-model="requestFields.currency"
          class="currency-select"
          :placeholder="t('signup.invitation.selectCurrencyPlaceholder')"
          popper-class="currency-select-pooper"
          :options="translatedCurrencyList"
          :has-error="Boolean(errors.currency?.message)"
          option-text="name"
          option-value="short"
          has-arrow
          @change="currentStep = 2"
        >
          <template v-if="requestFields.currency" #prefix>
            <CountryFlag :currency="requestFields.currency" size="18px" style="margin: 0px -5px 0px 9px;" />
          </template>
          <template #option="{ item }">
            <div class="d-flex w-100">
              <CountryFlag :currency="item.short" size="18px" style="margin-right: 12px;" />
              <AppText>{{ item.name }}</AppText>
              <FSpacer />
              <AppText variant="div" style="opacity: 0.5; margin-right: 20px;">
                {{ item.symbol }}
              </AppText>
              <AppText variant="div" style="opacity: 0.5; width: 28px;">
                {{ item.short }}
              </AppText>
            </div>
          </template>
        </FSelect>

        <AppText :line-height="1.5" color="var(--color-black-05)" class="hint">
          <i18n-t keypath="signup.invitation.currencyDescription">
            <span class="font-medium">{{ t('signup.invitation.currencyDescriptionSubtext') }}</span>
          </i18n-t>
        </AppText>

        <SimpleTransition
          :style="`
            min-height: ${requestFields.currency ? '161px' : '0px'};
            overflow: hidden;
          `"
        >
          <QrCodeInput
            v-if="requestFields.currency"
            :key="isReadyForGenerate"
            v-model="requestFields.code"
            :text="qrCodeText"
            :error="errors?.code?.message"
            :is-ready="isReadyForGenerate"
          />
        </SimpleTransition>

        <div class="d-flex justify-content-center mt-6" style="margin-bottom: -10px">
          <FButton :type="buttonType" :loading="isProcessing" @click="action">
            {{ t('signup.button.createAccount') }}
          </FButton>
        </div>
      </template>
    </ActionForm>

    <RecoveryCodes v-else-if="currentStep === 3" key="recovery-codes" @next="onNext" />
    <FinalStep v-else-if="currentStep === 4" key="final-step" :uid="uid" />
  </TransitionGroup>
</template>

<script>
import {
  computed, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

import CountryFlag from '@/components/Icon/CountryFlag.vue';
import SimpleTransition from '@/components/Animation/SimpleTransition.vue';

import {
  ActionForm, codeRule, enumRule, useValidation, yup,
} from '@/validation';
import { useTemporaryStore } from '@/store';
import { currencyList } from '@/common/data';
import { i18n } from '@/plugins/localization';
import { usePageLeaving } from '@/composables/usePageLeaving';

import ConnectedAlert from './components/ConnectedAlert.vue';
import FinalStep from './Steps/FinalStep.vue';
import QrCodeInput from './Steps/QrCodeInput.vue';
import RecoveryCodes from './Steps/RecoveryCodes.vue';
import { completeInvitation, twoFaEnable } from './api';

export default {
  name: 'SignupInvitation',
  components: {
    ActionForm,
    SimpleTransition,
    CountryFlag,
    ConnectedAlert,

    QrCodeInput,
    RecoveryCodes,
    FinalStep,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { initState, fieldsErrors, processError } = useValidation();

    const fields = {
      code: '',
      currency: '',
    };
    const validationSchema = yup.object().shape({
      currency: enumRule,
      code: codeRule,
    });
    const { requestFields } = initState(fields);
    const { store } = useTemporaryStore();

    const email = ref('');
    const companyName = ref('');
    const isReadyForGenerate = ref(false);
    const currentStep = ref(1);

    watch(computed(() => props.data), () => {
      if (props.data?.email) {
        email.value = props.data.email;
        companyName.value = props.data.companyName;
        isReadyForGenerate.value = true;
        currentStep.value = 1;
      }
    }, { immediate: true });

    const uid = ref('');
    const isProcessing = ref(false);
    const onValidate = async () => {
      isProcessing.value = true;
      const { isSuccess, errorObject } = await twoFaEnable(requestFields.code);
      const { data } = await completeInvitation(requestFields.currency);
      if (isSuccess) {
        currentStep.value = 3;
        uid.value = data.uid;

        store.updateRegistrationState({
          uid: uid.value,
          companyName: companyName.value,
          revokeAt: dayjs().add(30, 'minute'),
        });
      } else {
        processError(errorObject, 'wrongToken', 'code');
      }
      isProcessing.value = false;
    };

    const buttonType = computed(() => (requestFields.currency && requestFields.code ? 'primary' : 'plain'));

    const onNext = async () => {
      currentStep.value = 4;
    };

    const qrCodeText = computed(() => {
      if (requestFields.currency) {
        return `Switch: ${companyName.value} (${requestFields.currency})`;
      }
      return `Switch: ${companyName.value}`;
    });

    const { t } = i18n.global;

    const translatedCurrencyList = computed(() => currencyList.map((currency) => ({
      ...currency,
      name: t(`currency.${currency.countryCode}`),
    })));

    usePageLeaving({
      watchedRef: computed(() => currentStep.value),
      condition: (val) => (val.value > 1 || Boolean(requestFields.currency)) && val.value < 4,
      initialValue: 1,
    });

    return {
      translatedCurrencyList,
      isProcessing,
      currentStep,
      isReadyForGenerate,

      email,
      companyName,
      qrCodeText,

      requestFields,
      validationSchema,
      fieldsErrors,
      buttonType,
      onValidate,

      onNext,
      uid,
    };
  },
};
</script>

<style lang="scss" scoped>
.hint {
  display: block;
  margin-bottom: 28px !important;
}
.currency-select {
  margin-bottom: 8px !important;
  height: 54px;
  :deep(.el-input__wrapper) {
    padding-right: 20px;
  }
  :deep(.select-trigger .el-input__inner) {
    height: 52px !important;
    font-size: 15px !important;
    padding-left: 8px !important;
  }
}

:global(.currency-select-pooper .el-select-dropdown__wrap) {
  max-height: 212px !important;
}

.action-form {
  opacity: 0;
  @include transition-base(opacity);
  &.is-loaded {
    opacity: 1;
  }
}
</style>
