<template>
  <div class="connected-alert">
    <AppIcon
      name="arrow-check-done"
      size="18px"
      is-img-tag
      :opacity="0.4"
      style="margin-right: 12px;"
    />
    <AppText size="14px" :line-height="1.5" style="display: block; position: relative; top:-1px;">
      <slot />
    </AppText>
  </div>
</template>

<style scoped lang="scss">
.connected-alert {
  background: var(--color-F7F7F7);
  border-radius: 4px;
  height: 52px;
  padding: 20px;
  padding-left: 20px;
  margin-bottom: 12px;

  @include flex-center;
  justify-content: flex-start;
}
</style>
